<template>
    <div class="Home">

      <page-header/>

      <div class="uk-flex uk-flex-center uk-flex-middle uk-background-muted uk-height-viewport">
        <div class="uk-width-large uk-margin uk-section">

          <div v-if="error!==null" class="uk-alert-warning" uk-alert>
            <p v-html="error" class="uk-text-center"></p>
          </div>
          <div v-if="message!==null" class="uk-alert-success" uk-alert>
            <p v-html="message" class="uk-text-center"></p>
          </div>

          <form @submit.prevent="register" method="post">
            <fieldset class="uk-fieldset">
              <legend class="uk-legend">{{$t('register.reg')}}</legend>

              <div class="uk-margin">
                <div class="uk-inline uk-width-1-1">
                  <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: user"></span>
                  <input
                    class="uk-input uk-form-large"
                    v-model="username"
                    required
                    placeholder="Your name"
                    type="text"
                  />
                </div>
              </div>
              <div class="uk-margin">
                <div class="uk-inline uk-width-1-1">
                  <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: mail"></span>
                  <input
                    class="uk-input uk-form-large"
                    v-model="email"
                    required
                    placeholder="Email"
                    type="email"
                  />
                </div>
              </div>


              <div class="uk-margin">
                <div class="uk-inline uk-width-1-1">
                  <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: lock"></span>
                  <input
                    class="uk-input uk-form-large"
                    v-model="password"
                    required
                    placeholder="Password"
                    type="password"
                  />
                </div>
              </div>


              <div class="uk-margin">
                <button
                  type="submit"
                  class="uk-button uk-button-primary uk-button-primary uk-button-large uk-width-1-1"
                >{{$t('register.reg')}}
                </button>
              </div>
              <router-link
                class="uk-flex uk-flex-center uk-margin"
                title="Login"
                tag="a"
                :to="{name: 'login'}"
              >{{$t('register.alreg')}}
              </router-link>
            </fieldset>

          </form>
        </div>
      </div>

      <page-footer/>

    </div>
</template>

<script>
  import AuthenticationService from '@/services/AuthenticationService'
  import PageHeader from '@/components/Homecmp/Header.vue'
  import PageFooter from '@/components/Homecmp/Footer.vue'

  export default {
    name: 'Home',
    components: {
      PageHeader,
      PageFooter,
    },
    data() {
      return {
        email: '',
        username: '',
        password: '',
        error: null,
        message: null
      }
    },
    watch: {},
    methods: {
      async checkorgcode(value) {
        try {
           await AuthenticationService.checkorgcode({
            orgcode: value
          })
        } catch (error) {
          this.error = error.response.data.message
        }
      },
      async register() {
        this.error = null
        this.message = null

        try {
          const response = await AuthenticationService.register({
            firstName: this.username,
            username: this.email,
            email: this.email,
            password: this.password,
            type: 'student'
          })

          if (response.data.status == 200) {
            if (response.data.success == true) {
              this.message = response.data.message

              this.email = ''
              this.username = ''
              this.password = ''

            } else {
              this.error = response.data.message;
            }

          } else {
            this.error = response.data.message;
          }


          // this.$router.push({
          //   name: 'login'
          // })
        } catch (error) {
          this.error = error.response.data.message
        }
      }
    }
  }
</script>

<style scoped>
</style>
